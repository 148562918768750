.scope {
    padding: 75px 0px 150px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px 60px 0px;
    }
    h3 {
        margin-bottom: 28px;
    }

    .scope-content {
        margin-left: 116px;
        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }
}