/* Styles for the homepage hero element */
.hero-standard {
    height: 100vh;
    min-height: 500px;
    position: relative;
    margin-bottom: 125px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
.line-graphic {
    display: block;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -94px;
    z-index: 1;
    
    user-drag: none;
    -webkit-user-drag: none;
    
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.hero-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    justify-content: center;

    // .overlay {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     height: 100%;
    //     display: block;
    //     background-color: rgba(0, 0, 0, 0.28);
    //     width: 100%;
    // }
    &:before {
        content: "";
        display: block;
        height: 50px;
        width: 1px;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        z-index: 1;
    }
    &:after {
        content: "";
        display: block;
        height: 50px;
        width: 1px;
        margin: 0 auto;
        position: absolute;
        bottom: -50px;
        background-color: #000;
    }
    h1 {
        color: #FFFFFF;
        font-size: 28px;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
        z-index: 1;
    }
}
}
