.lightbox-carousel {
  padding: 75px 0px;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }
  .lcc-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 750px;
    width: 100%;
    display: block;

    @media (max-width: 1400px) {
      height: 660px;
    }
    @media (max-width: 1200px) {
      height: 570px;
    }
    @media (max-width: 991px) {
      height: 440px;
    }
    @media (max-width: 767px) {
      height: 340px;
    }
    @media (max-width: 576px) {
      height: 250px;
    }
  }
  /* Remove default styling applied to the dots by the ul element */
  .slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .slick-arrow {
    display: none;
  }

  /* Style the dots */
  .slick-dots li {
    display: inline-block;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    margin-left: 15px;
    width: 8px;
    height: 8px;
    padding: 0px;
    border: 1px solid white;
    border-radius: 50%;
    background: transparent;
    outline: none;
  }

  /* Style the active dot */
  .slick-dots li.slick-active button {
    background: white;
  }

  /* Position the dots in the bottom right of the parent */
  .slick-dots {
    bottom: 41px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0 !important;
    text-align: center;
    z-index: 1;

    @media (max-width: 576px) {
      bottom: 20px;
    }
  }
}

.slick-lightbox {
  .slick-arrow {
    display: none !important;
  }
}
