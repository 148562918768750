.three-column-content {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }

    .tcc-image {
        padding-top: 100%;
        margin-bottom: 30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    h3 {
        color: #473627;
        font-family: DanmarkURWLig;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .bold {
        font-weight: 400;
    }

    .tcc-row {
        --bs-gutter-x: 5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
}