.content-image-large {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    .cil-image {
        display: block;
        padding-top: 100%;
        background-color: #B5A58F;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
    }
    
    .row {
        --bs-gutter-x: 4.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    h3 {
        margin-bottom: 20px;
    }
    
    
    
}

.content-image-large.img-right {
    .cil-content {
        padding-left: 80px;
        @media (max-width: 991px) {
            padding-left: 0px;
        }
    }
    .col-lg-7 {
        order: 2; 

        @media (max-width: 991px) {
            order: 1 !important
            ;
        }
    }
    
    .row {
        
        div {
            @media (max-width: 991px) {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
            }
        }
    }
}
.content-image-large.img-left {
    p {
        width: 80%;
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    
}

.content-image-large.cil-image-link {
    .cil-image {
        position: relative;

        
        span {
            color: #FFFFFF;
            font-family: DanmarkURWDem;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            position: absolute;
            left: 40px;
            bottom: 38px;
            &:after {
                content: "";
                position: absolute;
                right: -28px;
                top: 1px;
                background-image: url(/wp-content/themes/pernille-lind-studio/src/images/arrow.svg);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 13px;
                width: 17px;
            }
        }
    }
}