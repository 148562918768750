.image-collage {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }

    .row-1-ic {
        --bs-gutter-x: 7rem;
        @media (max-width: 1200px) {
            --bs-gutter-x: 5rem;
        }
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
        .collage-1 {
            padding-top: 137%;
        }
    
        .collage-2 {
            padding-top: 100%;
            margin-top: -25px;
        }
    }
    .row-2-ic {
        --bs-gutter-x: 7rem;
        @media (max-width: 1200px) {
            --bs-gutter-x: 5rem;
        }
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
        .collage-3 {
            padding-top: 84%;
            margin-left: 116px;
            margin-top: 120px;
        }
    
        .collage-4 {
            padding-top: 124%;
            margin-top: -175px;
            margin-right: 90px;
        }
    }

    .collage-item {
        background-color: #fff;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
        @media (max-width: 991px) {
            margin: 0px 0px 30px 0px !important;
        }
    }

   
}