* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #f2f1ed;
  @media (max-width: 991px) {
    padding-top: 57px;
  }
}

h1 {
  font-family: DanmarkURWLig;
}

h3 {
  color: #473627;
  font-family: DanmarkURWLig;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
}

h4 {
  color: #473627;
  font-family: DanmarkURWLig;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
}

h5 {
  color: #473627;
  font-family: DanmarkURWLig;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1380px;
  }
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  color: #473627;
  margin-bottom: 25px;

  a {
    color: #473627;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      color: #473627;
    }
  }
}

p.alt {
  color: #473627;
  font-family: DanmarkURWLig;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px;
}

.link-standard {
  color: #473627;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0.86px;
  line-height: 14px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 1px solid #473627;
  padding-bottom: 2px;

  &:hover {
    color: #473627;
    text-decoration: none;
    opacity: 0.7;
  }
}

.container {
  @media (max-width: 576px) {
    padding: 0px 20px;
  }
}

ul {
  padding-left: 20px;
  li {
    color: #473627;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
  }
}

.alt-nav-padding {
  padding-top: 200px;
  @media (max-width: 767px) {
    padding-top: 100px;
  }
  @media (max-width: 576px) {
    padding-top: 40px;
  }
}

.plain-text {
  padding: 75px 0px;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }
  h1 {
    margin-bottom: 40px;
  }
}
