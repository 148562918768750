.text-block {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    .row {
        --bs-gutter-x: 5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    p {
        padding-right: 50px;
        @media (max-width: 576px) {
            padding-right: 0px;
        }
    }
}