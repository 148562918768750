.full-width-image {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }

    .fwi-image {
        background-color: #fff;
        padding-top: 55%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}