.press-content {
  padding: 75px 0px;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }
  .row {
    --bs-gutter-x: 5.5rem;
    @media (max-width: 991px) {
      --bs-gutter-x: 0rem;
    }
  }

  h1 {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .press-container {
    -moz-column-count: 2;
    column-count: 2;
    width: 30em;
    margin-bottom: 40px;
    @media (max-width: 576px) {
      display: block;
      column-count: 1;
    }
    .item {
      -webkit-column-break-inside: avoid;
    }
  }
  strong {
    color: #473627;
    font-family: Roboto;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 36px;
    font-weight: 500;
    display: block;
    margin-bottom: 15px;
  }

  a {
    display: block;
    margin-bottom: 10px;
    color: #473627;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 28px;
    text-decoration: none;
    transition: 0.1s all ease-in-out;

    span {
      display: block;
      font-size: 14px;
      line-height: 16px;
      font-style: italic;
    }
    &:hover {
      color: #473627;
      opacity: 0.6;
    }
    &:last-child {
      margin-bottom: 40px;
    }
  }

  .images {
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .img-right {
    margin-left: auto;
    margin-bottom: auto;
    background-color: #b5a58f;
    height: 537px;
    width: 410px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    @media (max-width: 576px) {
      margin-right: auto;
      width: auto;
      height: auto;
      padding-top: 125%;
    }
  }

  .img-left {
    background-color: #7e6346;
    height: 537px;
    width: 410px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: -368px;
    @media (max-width: 576px) {
      margin-top: 30px;
      width: auto;
      height: auto;
      padding-top: 125%;
    }
  }
}
