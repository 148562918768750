.careers {
  padding: 100px 0px;
  @media (max-width: 576px) {
    padding: 60px 0px;
  }

  .intro {
    margin-bottom: 62px;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
    @media (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  .job-content {
    p {
      a {
        color: #473627;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          color: #473627;
          text-decoration: none;
        }
      }
    }
  }

  ul {
    li {
      color: #473627;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 38px;
    }
  }

  .page-break {
    position: relative;
    margin-bottom: 80px;
    &:before {
      content: "";
      height: 3px;

      position: absolute;
      background-color: #f2f1ed;
      width: 155px;
      left: 0;
      right: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      top: 0px;
      z-index: 1;
    }
    img {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      top: -12px;
      z-index: 2;
    }
  }

  hr {
    background-color: #c9c2b8;
    opacity: 1;
  }
}
