.content-image {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    
    .row {
        --bs-gutter-x: 5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    h3 {
        margin-bottom: 25px;
    }
    .content-image-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .content {
        padding-right: 70px;
        @media (max-width: 576px) {
            padding-right: 0px;
        }
    }
}