
.tear-sheet {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    .tear-sheet-intro {
        h1 {
            text-align: center;
            margin-bottom: 30px;
            margin-bottom: 70px;
            
        }

        
    }
    .row {
        --bs-gutter-x: 5.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    .tear-sheet-content {
        .col-12 {
            margin-bottom: 40px;
        }
    }

    .img-container {
        flex-grow: 1;

        margin-bottom: 10px;
    }

    .tear-sheet-item {
        
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        h4 {
            text-align: center;
        }

        p {
            text-align: center;
            font-size: 14px;
        }

        a {
            width: 80px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
}