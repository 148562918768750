.about-intro {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    
    .row {
        --bs-gutter-x: 5.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    .about-intro-image {
        background-color: #fff;
        width: 385px;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 0.72;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media (max-width: 991px) {
            margin-top: 30px;
        }
        @media (max-width: 576px) {
            width: auto;
        }
    }
}

.quote {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    h3 {
        text-align: center;
        margin-bottom: 35px;
    }
    span {
        font-family: aAutoSignature;
        color: #000000;
        font-size: 28px;
        letter-spacing: -1.75px;
        line-height: 32px;
        text-align: center;
        display: block;
    }
}

.about-content {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    h3 {
        margin-bottom: 27px;
    }
    h4 {
        margin-bottom: 20px;
    }
    
    .content-row {
        --bs-gutter-x: 5.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    ul {
        li {
            margin-bottom: 5px;
        }
    }
}