.contact-page {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }

    p {
        text-align: center;
        margin-bottom: 40px;
    }

    a {
        color: #473627;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        text-decoration: none;
        &:hover {
            color: #473627;
            text-decoration: none;
        }
    }

    #googlemap {
        margin-top: 50px;
        padding-top: 61%;
    }
}