.team-inner {
    padding: 150px 0px;
    @media (max-width: 576px) {
        padding: 70px 0px;
    }
    
    .row {
        --bs-gutter-x: 1.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    .team-page-image {
        background-color: #fff;
        width: 385px;
        margin-left: auto;
        margin-right: auto;
        aspect-ratio: 0.72;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
        @media (max-width: 576px) {
            width: auto;
        }
    }
}