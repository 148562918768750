.collection-page-listing {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    .collection-item {
            margin-bottom: 62px;
    }
    h5 {
        text-align: center;
        text-decoration: none;
    }
    
    .row {
        --bs-gutter-x: 3.5rem;
        @media (max-width: 767px) {
            --bs-gutter-x: 0rem;
        }
    }
    
    .collection-item {
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
        display: block;
        text-decoration: none;
    }
    
    .collection-item-image {
        margin-bottom: 32px;
        @media (max-width: 991px) {
            margin-bottom: 25px;
        }
        position: relative;
        img {
            margin: auto;
            position: absolute;
            top: 0; left: 0; bottom: 0; right: 0;
            max-height: 100%;
            max-width: 100%;
            padding: 15px;
        }
    }
    
    .link-container {
        text-align: center;
    }
    
    .collection-list {
        margin-bottom: 55px;
        @media (max-width: 991px) {
            margin-bottom: 15px;
        }
    }
}