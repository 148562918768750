.nav-top.scrolled {
  opacity: 0;
  pointer-events: none;
}

.nav-top.alt-nav {
  #Home {
    fill: #473627;
  }
  .menu-item-has-children {
    > a {
      &:hover {
        color: #473627 !important;
        -webkit-text-stroke: 0.7px #473627 !important;
      }
    }
    &:hover {
      > a {
        color: #473627 !important;
        border-bottom: 1px solid #473627 !important;
        -webkit-text-stroke: 0.7px #473627 !important;
      }
    }
  }
  a {
    color: #473627 !important;
    &:hover {
      color: #2d2218 !important;
    }
  }
}

.nav-top {
  display: flex;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 35px;
  width: 100%;
  z-index: 2;
  @media (max-width: 991px) {
    display: none;
  }
  .logo-container {
    text-align: center;
    margin-bottom: 32px;
  }

  .menu-item-has-children {
    > a {
      cursor: default;
      &:hover {
        color: #fff !important;
        opacity: 1 !important;
        -webkit-text-stroke: 0.7px #fff;
      }
    }
    &:hover {
      > a {
        color: #fff !important;
        border-bottom: 1px solid #fff !important;
        opacity: 1 !important;
        -webkit-text-stroke: 0.7px #fff;
      }
      ul.sub-menu {
        display: block;
      }
    }
  }

  .menu-primary-nav-container {
    text-align: center;

    .menu {
      display: inline-block;
      margin-bottom: 0px;
      padding-left: 0px;
    }

    .menu-item {
      display: inline-block;
      margin-left: 22.5px;
      margin-right: 22.5px;

      a {
        color: white;
        text-decoration: none;
        font-size: 12px;
        letter-spacing: 0.43px;
        line-height: 18px;
        padding-bottom: 2px;
        font-weight: 400;
        display: inline-block;
        font-family: brandon-grotesque, sans-serif;
        text-transform: uppercase;
        position: relative;
        transition: all 0.1s ease-in-out;
        border-bottom: 1px solid transparent;
        &:hover {
          color: #ccc;
        }
      }
    }

    .sub-menu {
      display: none;
      position: absolute;
      color: white;
      padding: 0px;
      padding-top: 5px;
      li {
        display: block;
        margin: 10px 0px;
        text-align: left;
        a {
          font-weight: 500 !important;
        }
      }

      &:hover {
        display: block;
      }
    }
  }
}

.nav-scrolled.scrolled {
  opacity: 1;
  pointer-events: all;
}
.nav-scrolled {
  display: flex;
  opacity: 0;
  pointer-events: none;
  background-color: #f2f1ed;
  position: fixed;
  z-index: 2;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.08%);
  @media (max-width: 991px) {
    display: none;
  }
  .menu {
    > li {
      padding: 18px 0px;
    }
  }
  img {
    margin: 0 56px;
    padding: 18px 0px;
  }

  .sub-menu {
    position: absolute;
    // background: #f2f1ed;
    // left: 0;
    // right: 0;
    padding: 13px 0px;
    top: 60px;
  }
  .nav-s-inner {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
  }

  #menu-menu-left {
    text-align: right;
    li {
      margin-left: 45px;
    }

    .sub-menu {
      pointer-events: none;
      li {
        margin-left: 0px;
        margin-right: 45px;
      }
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  #menu-menu-right {
    li {
      margin-right: 45px;
    }
  }

  .menu-item-has-children {
    > a {
      cursor: default;
    }
    &:before {
      content: "";
      height: 50px;
      background: #f2f1ed;
      left: 0;
      right: 0;
      position: absolute;
      top: 60px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      pointer-events: none;
    }
    &:hover {
      &:before {
        opacity: 1 !important;
        pointer-events: all !important;
      }
      .sub-menu {
        opacity: 1 !important;
        pointer-events: all !important;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      a {
        color: #473627;
        font-size: 12px;
        letter-spacing: 0.43px;
        line-height: 18px;
        font-family: brandon-grotesque, sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        transition: all 0.1s ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
  padding: 16px 17px;
  position: fixed;
  background-color: #f2f1ed;
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.08%);
  z-index: 2;
  top: 0;
  width: 100%;
  @media (max-width: 991px) {
    display: block;
  }

  .mobile-logo {
    width: 30px;
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ #overlay {
    transform: none;
  }

  input[type="checkbox"]:checked ~ #overlay-button {
    span {
      background-color: #473627;
      &:before {
        background-color: #473627;
      }
      &:after {
        background-color: #473627;
      }
    }
  }

  .sub-menu {
    display: none;
  }

  input[type="checkbox"]:checked ~ #overlay-button {
    &:hover span,
    span {
      background: transparent;
    }
    span {
      &:before {
        transform: rotate(45deg) translate(7px, 7px);
        opacity: 1;
      }
      &:after {
        transform: rotate(-45deg) translate(7px, -7px);
      }
    }
  }
}

#overlay {
  height: 100vh;
  width: 100vw;
  background: #f2f1ed;
  z-index: 10;
  transform: translateX(100vw);
  position: fixed;
  transition: all 0.2s ease-in-out;
  left: 0;
  top: 0;
  &.active {
    transform: none;
  }

  .menu-item-has-children {
    .slideopen {
      &:after {
        transform: rotate(180deg) scaleX(-1);
      }
    }

    & > a {
      position: relative;

      &:after {
        content: "";
        background-image: url("/wp-content/themes/coquet-cottages/src/images/caret-down.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 4px;
        width: 8px;
        position: absolute;
        right: 5%;
        top: -2px;
        transition: all 0.2s ease-in-out;
      }
    }

    & > a {
      display: block;
    }
  }

  ul.sub-menu {
    padding-left: 0px;
    margin-top: 20px;
    list-style: none;
    li {
      padding-bottom: 10px;
      a {
        font-size: 14px;
      }
    }
  }
  .menu {
    padding: 50px 0px;
    padding-left: 0;
    list-style-type: none;
    margin-left: 23px;
    li {
      padding-bottom: 20px;
      a {
        text-decoration: none;
        color: #473627;
        font-family: DanmarkURWLig;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 32px;
        width: 100%;
        text-align: left;
        &:hover {
          color: #473627;
        }
      }
    }
  }
}

@keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}
@-webkit-keyframes bugfix {
  from {
    padding: 0;
  }
  to {
    padding: 0;
  }
}

#overlay-button {
  position: absolute;
  right: 10px;
  top: 8px;
  padding: 18px 11px;
  z-index: 99;
  cursor: pointer;
  user-select: none;
  span {
    height: 2px;
    width: 28px;
    border-radius: 2px;
    background-color: #473627;
    position: relative;
    display: block;
    transition: all 0.2s ease-in-out;
    &:before {
      top: -10px;
      visibility: visible;
    }
    &:after {
      top: 10px;
    }
    &:before,
    &:after {
      height: 2px;
      width: 28px;
      border-radius: 2px;
      background-color: #473627;
      position: absolute;
      content: "";
      transition: all 0.2s ease-in-out;
    }
  }
}
