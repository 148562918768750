/* Styles for the homepage hero element */
.homepage-hero {
    height: 100vh;
    min-height: 500px;
    position: relative;
    margin-bottom: 75px;
    @media (max-width: 576px) {
        margin-bottom: 100px;
        min-height: 350px;
        height: 80vh;
    }
    .fade {
        height: 100%;
        opacity: 1;
        .slick-list {
            height: 100%;
            .slick-track {
                height: 100%;
            }
        }
    }
    /* Remove default styling applied to the dots by the ul element */
    .slick-dots {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    
    /* Style the dots */
    .slick-dots li {
        display: inline-block;
    }
    
    .slick-dots li button {
        font-size: 0;
        line-height: 0;
        margin-left: 15px;
        width: 8px;
        height: 8px;
        padding: 0px;
        border: 1px solid white;
        border-radius: 50%;
        background: transparent;
        outline: none;
    }
    
    /* Style the active dot */
    .slick-dots li.slick-active button {
        background: white;
    }
    
    /* Position the dots in the bottom right of the parent */
    .slick-dots {
        bottom: 38px;
        right: 20px;
        position: absolute;
        z-index: 1;
    }
}

.slick-slide {
    .img {
        animation: backgroundScroll 70s linear infinite;
        scale: 1;
    }
}

.hero-img {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    a {
        position: absolute;
        left: 20px;
        bottom: 38px;
        z-index: 2;
        pointer-events: all;
        color: #FFFFFF;
        font-family: DanmarkURWDem;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-decoration: none;
        &:after {
            content: "";
            position: absolute;
            right: -28px;
            top: 1px;
            background-image: url(/wp-content/themes/pernille-lind-studio/src/images/arrow.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            height: 13px;
            width: 17px;
        }
    }
   
    
    
    .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateX(0);
        
    }
    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 1;
    //     background-color: rgba(0, 0, 0, 0.26);
    // }
}

.line-graphic {
    display: block;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -94px;
    z-index: 1;
    @media (max-width: 576px) {
        bottom: -60px;
    }
    user-drag: none;
    -webkit-user-drag: none;
    
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.hero-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    align-items: center;
    pointer-events: none;
    justify-content: center;
    &:before {
        content: "";
        display: block;
        height: 50px;
        width: 1px;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        background-color: #fff;
        z-index: 1;
        @media (max-width: 576px) {
            height: 20px;
        }
    }
    &:after {
        content: "";
        display: block;
        height: 50px;
        width: 1px;
        margin: 0 auto;
        position: absolute;
        bottom: -50px;
        background-color: #000;
        @media (max-width: 576px) {
            height: 20px;
            bottom: -20px;
        }
    }
    h1 {
        color: #FFFFFF;
        font-size: 28px;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
    }

    p {
        color: #FFFFFF;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        width: 60%;
        display: block;
        margin: 0 auto;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}



@keyframes backgroundScroll {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
 

}