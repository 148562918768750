.team-content {
    padding: 75px 0px 25px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px 10px 0px;
    }
    a {
        display: block;
        color: #000000;
        font-family: aAutoSignature;
        font-size: 28px;
        letter-spacing: -1px;
        line-height: 32px;
        text-align: center;
        margin-bottom: 40px;
        text-decoration: none;
        transition: 0.1s all ease-in-out;
        &:hover {
            opacity: 0.6;
        }
    }
}