.project-listing {
    padding: 75px 0px;
.row {
    --bs-gutter-x: 4.5rem;
    @media (max-width: 991px) {
        --bs-gutter-x: 0rem;
    }
    .col-12:nth-child(even) {
        margin-top: 120px;
        @media (max-width: 991px) {
            margin-top: 0px;
        }
      }
}
    .pl-item {
        display: block;
        padding-top: 100%;
        background-color: #B5A58F;
        position: relative;
         margin-bottom: 10px;
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
        @media (max-width: 991px) {
            margin-bottom: 30px;
        }
        
        span {
            color: #FFFFFF;
            font-family: DanmarkURWDem;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;
            position: absolute;
            left: 40px;
            bottom: 38px;

            &:after {
                content: "";
                position: absolute;
                right: -28px;
                top: 1px;
                background-image: url(/wp-content/themes/pernille-lind-studio/src/images/arrow.svg);
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                height: 13px;
                width: 17px;
            }
        }

    }
}