.product-description {
    padding: 75px 0px;
    @media (max-width: 576px) {
        padding: 30px 0px;
    }
    .row {
        --bs-gutter-x: 5.5rem;
        @media (max-width: 991px) {
            --bs-gutter-x: 0rem;
        }
    }
    h3 {
        margin-bottom: 25px;
    }
    
    span {
        color: #473627;
        font-family: 'Cormorant', serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
    }
    .product-description-links {
        margin-top: 40px;
        a {
            margin-right: 38px;
            &:last-child {
                margin-right: 0px;
            }

            @media (max-width:1400px) {
                margin-right: 28px;
            }
            @media (max-width:1200px) {
                margin-bottom: 15px;
            }
        }
    }
    .images {
        @media (max-width: 767px) {
            margin-top: 30px;
        }
    }
    
    .img-right {
        margin-left: auto;
        margin-bottom:auto;
        background-color: #B5A58F;
        height: 537px;
        width: 410px;
        z-index: 1;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        @media (max-width: 576px) {
            margin-right: auto;
            width: auto;
            height: auto;
            padding-top: 125%;
        }
    }
    
    .img-left {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #7E6346;
        height: 537px;
        width: 410px;
        margin-top: -368px;
        @media (max-width: 576px) {
            margin-top: 30px;
            width: auto;
            height: auto;
            padding-top: 125%;
        }
    }
}