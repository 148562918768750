.footer {
  background-color: #fff;
  padding: 60px 0px;

  .footer-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 63px;
  }

  .menu-footer-menu-container {
    text-align: center;
    margin-bottom: 57px;
    .menu {
      display: inline-block;
      padding-left: 0px;
      margin-bottom: 0px;
    }

    .menu-item {
      display: inline-block;
      margin-left: 22.5px;
      margin-right: 22.5px;

      a {
        color: #473627;
        text-decoration: none;
        font-size: 12px;
        letter-spacing: 0.43px;
        line-height: 18px;
        font-weight: 400;
        display: inline-block;
        font-family: brandon-grotesque, sans-serif;
        text-transform: uppercase;
        position: relative;
        transition: all 0.1s ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }

    .sub-menu {
      display: none;
      position: absolute;
      background-color: #333;
      color: #473627;
      padding: 10px;

      &:hover {
        display: block;
      }
    }
  }

  .social-icons {
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      margin-bottom: 55px;
      li {
        display: inline-block;
        margin: 0px 10px;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #473627;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          transition: all 0.1s ease-in-out;
          .icon-hover {
            transition: all 0.1s ease-in-out;
          }
          &:hover {
            background-color: #473627;

            .icon-hover {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    display: grid;
    grid-template-columns: minmax(180px, 1fr) auto minmax(180px, 1fr);
    @media (max-width: 767px) {
      display: block;
    }
    .left {
      text-align: left;
      @media (max-width: 767px) {
        text-align: center;
        margin-bottom: 30px;
      }
      a {
        color: #473627;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 26px;
        text-decoration: none;
        margin-right: 30px;
        @media (max-width: 767px) {
          margin: 0px 15px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .centre {
      p {
        color: #666666;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        margin-bottom: 0px;
        text-decoration: none;
        color: #666666 !important;
      }
    }
    .right {
      text-align: right;
      @media (max-width: 767px) {
        text-align: center;
        margin-top: 20px;
      }
      .ne6 {
        color: #473627;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 26px;
        text-align: right;
        font-weight: 300;
        a {
          color: #473627;
          text-decoration: none;
          font-weight: 400;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
